const { devEufyEuApps } = require('./eufyEu');

const usDev = [
  {
    app: 'beta-anker-us',
    domain: 'https://beta-anker-us.myshopify.com',
    brand: 'anker',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-ankerus',
    domain: 'https://beta-ankerus.myshopify.com',
    brand: 'anker',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-ankermake-us',
    domain: 'https://beta.ankermake.com',
    brand: 'ankermake',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-ankerwork-us',
    domain: 'https://beta-ankerwork-us.myshopify.com',
    brand: 'ankerwork',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-soundcore-us',
    domain: 'https://beta-soundcore-us.myshopify.com',
    brand: 'soundcore',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-soundcore',
    applicationName: 'beta-soundcore-shopify',
    domain: 'https://beta-soundcore.myshopify.com',
    brand: 'soundcore',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-eufy-us',
    domain: 'https://beta.eufy.com',
    brand: 'eufy',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-nebula-us',
    domain: 'https://beta-nebula-us.myshopify.com',
    brand: 'nebula',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-mach-us',
    domain: 'https://beta.mach.tech',
    brand: 'mach',
    country: 'us',
    lang: 'en',
  },
  // ankermake 相关，只提供了注册/激活
  {
    app: 'beta-makeitreal',
    domain: 'https://playground-qa.mkitreal.com/',
    brand: 'ankermake',
    country: 'us',
    lang: 'en',
    privacyPolicy: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-privacy.en.html',
    termsOfService: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-terms-of-service.en.html',
    type: 'app',
  },
];

const ukDev = [
  {
    app: 'beta-anker-uk',
    domain: 'https://beta-anker-uk.myshopify.com',
    brand: 'anker',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-ankerwork-uk',
    domain: 'https://beta-ankerwork-uk.myshopify.com',
    brand: 'ankerwork',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-ankerwork-uk',
    domain: 'https://beta-ankerwork-uk.myshopify.com',
    brand: 'ankerwork',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-soundcore-uk',
    domain: 'https://beta-soundcore-uk.myshopify.com',
    brand: 'soundcore',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-eufy-uk',
    domain: 'https://beta.eufy.com/uk',
    brand: 'eufy',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-eufy-us-plus',
    domain: 'https://beta-eufy-us-plus.myshopify.com',
    brand: 'eufy',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'beta-nebula-uk',
    domain: 'https://beta-nebula-uk.myshopify.com',
    brand: 'nebula',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'beta-ankermake-uk',
    domain: 'https://beta.ankermake.com/uk',
    brand: 'ankermake',
    country: 'uk',
    lang: 'en',
  },
];

const euDev = [
  {
    app: 'beta-anker-eu',
    domain: 'https://beta.anker.com/eu-en',
    brand: 'anker',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'beta-soundcore-eu',
    domain: 'https://beta-soundcore-eu.myshopify.com',
    brand: 'soundcore',
    country: 'eu',
    lang: 'en',
  },
  {
    app: 'beta-eufy-eu',
    domain: 'https://beta.eufy.com/eu-en',
    brand: 'eufy',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'beta-nebula-eu',
    domain: 'https://beta-nebula-eu.myshopify.com',
    brand: 'nebula',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'beta-ankermake-eu',
    domain: 'https://beta.ankermake.com/eu-en',
    brand: 'ankermake',
    country: 'eu-en',
    lang: 'en',
  },
];

const caDev = [
  {
    app: 'beta-soundcore-ca',
    domain: 'https://beta-soundcore-ca.myshopify.com',
    brand: 'soundcore',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'beta-eufy-ca',
    domain: 'https://beta.eufy.com/ca',
    brand: 'eufy',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'beta-anker-ca',
    domain: 'https://beta-anker-ca.myshopify.com',
    brand: 'anker',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'beta-nebula-ca',
    domain: 'https://beta-nebula-ca.myshopify.com',
    brand: 'nebula',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'beta-ankermake-ca',
    domain: 'https://beta-ankermake-ca.myshopify.com',
    brand: 'ankermake',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'beta-ankerwork-ca',
    domain: 'https://beta-ankerwork-ca.myshopify.com',
    brand: 'ankerwork',
    country: 'ca',
    lang: 'en',
  },
];

const deDev = [
  {
    app: 'beta-anker-de',
    domain: 'https://beta-anker-de.myshopify.com',
    brand: 'anker',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'beta-ankerwork-de',
    domain: 'https://beta-ankerwork-de.myshopify.com',
    brand: 'ankerwork',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'beta-soundcore-de',
    domain: 'https://beta-soundcore-de.myshopify.com',
    brand: 'soundcore',
    country: 'de',
    lang: 'de',
  },
  {
    app: 'beta-eufy-de',
    domain: 'https://beta.eufy.com/eu-de',
    brand: 'eufy',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'beta-nebula-de',
    domain: 'https://beta-nebula-de.myshopify.com',
    brand: 'nebula',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'beta-ankermake-de',
    domain: 'https://beta-ankermake-de.myshopify.com',
    brand: 'ankermake',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'beta-mach-de',
    domain: 'https://beta-mach-de.myshopify.com',
    brand: 'mach',
    country: 'eu-de',
    lang: 'de',
  },
];

const esDev = [
  {
    app: 'beta-eufy-es',
    domain: 'https://beta.eufy.com/eu-es',
    brand: 'eufy',
    country: 'eu-es',
    lang: 'es',
  },
];

const frDev = [
  {
    app: 'beta-soundcore-fr',
    domain: 'https://beta-soundcore-fr.myshopify.com',
    brand: 'soundcore',
    country: 'fr',
    lang: 'fr',
  },
  {
    app: 'beta-anker-fr',
    domain: 'https://beta.anker.com/fr',
    brand: 'anker',
    country: 'fr',
    lang: 'fr',
  },
  {
    app: 'beta-eufy-fr',
    domain: 'https://beta.eufy.com/eu-fr',
    brand: 'eufy',
    country: 'eu-fr',
    lang: 'fr',
  },
  {
    app: 'beta-ankersolix-fr',
    domain: 'https://beta.ankersolix.com/fr',
    brand: 'ankersolix',
    country: 'fr',
    lang: 'fr',
  },
];

/** 测试环境荷兰语配置 */
const nlDev = [
  {
    app: 'beta-eufy-nl',
    domain: 'https://beta.eufy.com/nl',
    brand: 'eufy',
    country: 'nl',
    lang: 'nl',
  },
];

const jpDev = [
  {
    app: 'beta-anker-jp',
    domain: '',
    brand: 'anker',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-eufy-jp',
    domain: '',
    brand: 'eufy',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-nebula-jp',
    domain: '',
    brand: 'nebula',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-soundcore-jp',
    domain: '',
    brand: 'soundcore',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-ankerwork-jp',
    domain: '',
    brand: 'ankerwork',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-ankermake-jp',
    domain: '',
    brand: 'ankermake',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'beta-mach-jp',
    domain: '',
    brand: 'mach',
    country: 'jp',
    lang: 'jp',
  },
  // ankermake 相关，只提供了注册/激活
  {
    app: 'beta-makeitreal-jp',
    domain: 'https://playground-qa.mkitreal.com/',
    brand: 'ankermake',
    country: 'jp',
    lang: 'jp',
    privacyPolicy: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-privacy.ja.html',
    termsOfService: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-terms-of-service.ja.html',
    type: 'app',
  },
];

const itDev = [
  {
    app: 'beta-eufy-it',
    domain: 'https://beta.eufy.com/eu-it',
    brand: 'eufy',
    country: 'eu-it',
    lang: 'it',
  },
  {
    app: 'beta-ankersolix-it',
    domain: 'https://beta.ankersolix.com/it',
    brand: 'ankersolix',
    country: 'it',
    lang: 'it',
  },
];

const appDev = [
  {
    app: 'beta-eufy-app',
    dtcApp: 'beta-eufy-us',
    domain: 'https://beta.eufy.com',
    shopifyDomain: 'beta-eufy-us.myshopify.com',
    brand: 'eufy',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-eufy-app-eu',
    dtcApp: 'beta-eufy-eu',
    domain: 'https://beta.eufy.com/eu-en',
    shopifyDomain: 'beta-eufy-eu.myshopify.com',
    brand: 'eufy',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-soundcore-app',
    dtcApp: 'beta-soundcore-us',
    domain: 'https://beta-soundcore-us.myshopify.com',
    shopifyDomain: 'beta-soundcore-us.myshopify.com',
    brand: 'soundcore',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-soundcore-app-eu',
    dtcApp: 'beta-soundcore-eu',
    domain: 'https://beta-soundcore-eu.myshopify.com',
    shopifyDomain: 'beta-soundcore-eu.myshopify.com',
    brand: 'soundcore',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-anker-app',
    domain: 'https://beta.anker.com',
    shopifyDomain: 'beta-anker-us.myshopify.com',
    brand: 'anker',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-anker-app-eu',
    domain: 'https://beta.anker.com/eu-en',
    shopifyDomain: 'beta-anker-eu.myshopify.com',
    brand: 'anker',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-nebula-app',
    dtcApp: 'beta-nebula-us',
    domain: 'https://beta-nebula-us.myshopify.com',
    shopifyDomain: 'beta-nebula-us.myshopify.com',
    brand: 'nebula',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'beta-nebula-app-eu',
    dtcApp: 'beta-nebula-eu',
    domain: 'https://beta-nebula-eu.myshopify.com',
    shopifyDomain: 'beta-nebula-eu.myshopify.com',
    brand: 'nebula',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
];

const auDev = [
  {
    app: 'beta-soundcore-au',
    domain: 'https://beta-soundcore-au.myshopify.com',
    brand: 'soundcore',
    country: 'au',
    lang: 'en',
  },
  {
    app: 'beta-eufy-au',
    domain: 'https://beta.eufy.com/au',
    brand: 'soundcore',
    country: 'au',
    lang: 'en',
  },
];

const config = [
  ...usDev,
  ...ukDev,
  ...euDev,
  ...caDev,
  ...deDev,
  ...esDev,
  ...frDev,
  ...nlDev,
  ...jpDev,
  ...itDev,
  ...auDev,
  ...appDev,
];

module.exports = config;
